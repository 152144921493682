<template>
  <div class="mt-16 bg-white">
    <div class="p-16 bg-gray-300 sombra-1">
      <h1 class="font-bold text-5xl sm:text-6xl">Casas</h1>
      <div class="text-justify">
        <p>
          En viviendas ofrecemos un sistema constructivo propios que utiliza
          módulos de hormigón armado, premoldeados y vibrados.
        </p>
        <p>
          Las mismas se entregan semiterminadas, una vez armada y sellada no se
          puede desarmar o trasladar, los paneles se fabrican en la planta
          industrial son transportadas únicamente por equipos de grúas de la
          empresa y requiere mano de obra especializada para su construcción.
        </p>
        <p>
          Las placas pueden variar de acuerdo a la necesidad de cada proyecto.
          Su diseño permite lograr distintas viviendas de acuerdo a cada
          necesidad y futuras ampliaciones, conservando su aspecto formal dado
          por el hormigón que no requiere revoques,no tiene problemas de
          humedad, su terminación es lisa y se entregan listas a pintar, se le
          puede dar un acabado con cualquier tipo de pintura y se adhiere todo
          tipo de revestimiento.
        </p>
        <p>
          El plazo de armado es de aproximadamente 15 días y a un costo
          razonable. El sistema constructivo SUVIBA asegura una aislación
          térmica, hidráulica y acústica semejante a una pared de mampostería.
        </p>
      </div>
    </div>

    <div class="p-16 bg-gray-200 flex flex-col sombra-1">
      <div class>
        <h1 class="font-bold text-3xl text-blue-700">Características</h1>
      </div>
      <div class="md:flex flex-col">
        <div class="md:flex flex-row">
          <div
            class="flex-1 p-12 m-3 border-b-2 md:border-b-0 text-center bg-white rounded-lg shadow-md hover:shadow-xl transition-shadow duration-700"
          >
            <font-awesome-icon
              class="icon-red text-red-500"
              icon="home"
              size="3x"
            />
            <h3 class="font-semibold text-xl">Semiterminadas</h3>
            <p class="mt-auto">
              Las casas se entregan semiterminadas, pudiendo el cliente elegir
              como y con quien terminarlas.
            </p>
          </div>
          <div
            class="flex-1 p-12 m-3 border-b-2 md:border-b-0 text-center bg-white rounded-lg shadow-md hover:shadow-xl transition-shadow duration-700"
          >
            <font-awesome-icon
              class="icon-red text-red-500"
              icon="edit"
              size="3x"
            />
            <h3 class="font-semibold text-xl">Personalizables</h3>
            <p>
              Los diseños pueden variar de acuerdo a la necesidad de cada
              proyecto.
            </p>
          </div>
          <div
            class="flex-1 p-12 m-3 border-b-2 md:border-b-0 text-center bg-white rounded-lg shadow-md hover:shadow-xl transition-shadow duration-700"
          >
            <font-awesome-icon
              class="icon-red text-red-500"
              icon="faucet"
              size="3x"
            />
            <h3 class="font-semibold text-xl">Sin problemas de humedad</h3>
            <p>
              Con lozas macizas y sin revoques olvidate de problemas de humedad
            </p>
          </div>
        </div>
        <div class="md:flex flex-row">
          <div
            class="flex-1 p-12 m-3 border-b-2 md:border-b-0 text-center bg-white rounded-lg shadow-md hover:shadow-xl transition-shadow duration-700"
          >
            <font-awesome-icon
              class="icon-blue text-blue-500"
              icon="clock"
              size="3x"
            />
            <h3 class="font-semibold text-xl">Entregadas en poco tiempo</h3>
            <p>El plazo de armado es de aproximadamente 15 días!</p>
          </div>
          <div
            class="flex-1 p-12 m-3 border-b-2 md:border-b-0 text-center bg-white rounded-lg shadow-md hover:shadow-xl transition-shadow duration-700"
          >
            <font-awesome-icon
              class="icon-blue text-blue-500"
              icon="hand-holding-usd"
              size="3x"
            />
            <h3 class="font-semibold text-xl">Costo razonable</h3>
            <p>
              El costo es razonable, hay muchos diseños para adecuarse a tu
              presupuesto
            </p>
          </div>
          <div
            class="flex-1 p-12 m-3 border-b-2 md:border-b-0 text-center bg-white rounded-lg shadow-md hover:shadow-xl transition-shadow duration-700"
          >
            <font-awesome-icon
              class="icon-blue text-blue-500"
              icon="thermometer-half"
              size="3x"
            />
            <h3 class="font-semibold text-xl">Aislación térmica y acústica</h3>
            <p>
              Las lozas macizas aíslan la casa tanto térmica como acusticamente
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="py-16 px-1 bg-gray-200 sombra-2">
      <h2
        class="font-bold text-4xl sm:text-5xl w-full h-full text-center text-red-700"
      >
        Terminadas
      </h2>
      <carousel
        :per-page="perPagePhotos"
        centerMode
        loop
        touchDrag
        paginationColor="rgb(220 220 220)"
      >
        <slide
          v-for="(img, i) in terminados"
          :key="i"
          class="flex justify-center"
        >
          <img
            :style="breakpoints.is != 'sm' ? 'max-height: 500px' : ''"
            v-lazy="img"
          />
        </slide>
      </carousel>
    </div>
    <div class="py-16 px-1 bg-gray-300 sombra-1">
      <h2
        class="font-bold text-4xl sm:text-5xl w-full h-full text-center text-blue-600"
      >
        Interiores
      </h2>
      <carousel
        :per-page="perPagePhotos"
        centerMode
        loop
        touchDrag
        paginationColor="white"
      >
        <slide
          v-for="(img, i) in interiores"
          :key="i"
          class="flex justify-center"
        >
          <img
            :style="breakpoints.is != 'sm' ? 'max-height: 500px' : ''"
            v-lazy="img"
          />
        </slide>
      </carousel>
    </div>
    <div class="py-16 px-1 bg-gray-100 sombra-2">
      <h2
        class="font-bold text-4xl sm:text-5xl w-full h-full text-center text-red-600"
      >
        Construcción
      </h2>
      <carousel
        :per-page="perPagePhotos"
        centerMode
        loop
        touchDrag
        paginationColor="rgb(220 220 220)"
      >
        <slide
          v-for="(img, i) in construccion"
          :key="i"
          class="flex justify-center"
        >
          <img
            :style="breakpoints.is != 'sm' ? 'max-height: 500px' : ''"
            v-lazy="img"
          />
        </slide>
      </carousel>
      <!-- <hr class="mb-5 border-gray-600 border-t-1" /> -->
    </div>

    <div class="py-16 px-1 bg-gray-100 sombra-1">
      <h2
        class="font-bold text-4xl sm:text-5xl w-full h-full text-center text-blue-700"
      >
        Planos
      </h2>
      <carousel
        :per-page="perPagePhotos"
        centerMode
        loop
        touchDrag
        paginationColor="rgb(220 220 220)"
      >
        <slide v-for="(img, i) in planos" :key="i" class="flex justify-center">
          <img
            :style="breakpoints.is != 'sm' ? 'max-height: 500px' : ''"
            v-lazy="img"
          />
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import breakpoints from "@/plugins/breakpoints";

export default {
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      construccion: [
        require("@/assets/img/full/Casa/construccion/img (1).jpg"),
        require("@/assets/img/full/Casa/construccion/img (10).jpg"),
        require("@/assets/img/full/Casa/construccion/img (11).jpg"),
        require("@/assets/img/full/Casa/construccion/img (12).jpg"),
        require("@/assets/img/full/Casa/construccion/img (13).jpg"),
        require("@/assets/img/full/Casa/construccion/img (14).jpg"),
        require("@/assets/img/full/Casa/construccion/img (15).jpg"),
        require("@/assets/img/full/Casa/construccion/img (16).jpg"),
        require("@/assets/img/full/Casa/construccion/img (17).jpg"),
        require("@/assets/img/full/Casa/construccion/img (18).jpg"),
        require("@/assets/img/full/Casa/construccion/img (19).jpg"),
        require("@/assets/img/full/Casa/construccion/img (2).jpg"),
        require("@/assets/img/full/Casa/construccion/img (21).jpg"),
        require("@/assets/img/full/Casa/construccion/img (22).jpg"),
        require("@/assets/img/full/Casa/construccion/img (23).jpg"),
        require("@/assets/img/full/Casa/construccion/img (3).jpg"),
        require("@/assets/img/full/Casa/construccion/img (4).jpg"),
        require("@/assets/img/full/Casa/construccion/img (5).jpg"),
        require("@/assets/img/full/Casa/construccion/img (6).jpg"),
        require("@/assets/img/full/Casa/construccion/img (7).jpg"),
        require("@/assets/img/full/Casa/construccion/img (8).jpg"),
        require("@/assets/img/full/Casa/construccion/img (9).jpg"),
        require("@/assets/img/full/Casa/construccion/img (24).jpg")
      ],
      interiores: [
        require("@/assets/img/full/Casa/interiores/img (1).jpg"),
        require("@/assets/img/full/Casa/interiores/img (2).jpg"),
        require("@/assets/img/full/Casa/interiores/img (3).jpg"),
        require("@/assets/img/full/Casa/interiores/img (4).jpg"),
        require("@/assets/img/full/Casa/interiores/img (5).jpg"),
        require("@/assets/img/full/Casa/interiores/img (6).jpg")
      ],
      planos: [
        require("@/assets/img/full/Casa/planos/img (1).jpg"),
        require("@/assets/img/full/Casa/planos/img (2).jpg"),
        require("@/assets/img/full/Casa/planos/img (3).jpg"),
        require("@/assets/img/full/Casa/planos/img (4).jpg"),
        require("@/assets/img/full/Casa/planos/img (5).jpg"),
        require("@/assets/img/full/Casa/planos/img (6).jpg")
      ],
      terminados: [
        require("@/assets/img/full/Casa/terminados/img (1).jpg"),
        require("@/assets/img/full/Casa/terminados/img (10).jpg"),
        require("@/assets/img/full/Casa/terminados/img (11).jpg"),
        require("@/assets/img/full/Casa/terminados/img (12).jpg"),
        require("@/assets/img/full/Casa/terminados/img (13).jpg"),
        require("@/assets/img/full/Casa/terminados/img (14).jpg"),
        require("@/assets/img/full/Casa/terminados/img (15).jpg"),
        require("@/assets/img/full/Casa/terminados/img (16).jpg"),
        require("@/assets/img/full/Casa/terminados/img (17).jpg"),
        require("@/assets/img/full/Casa/terminados/img (18).jpg"),
        require("@/assets/img/full/Casa/terminados/img (19).jpg"),
        require("@/assets/img/full/Casa/terminados/img (2).jpg"),
        require("@/assets/img/full/Casa/terminados/img (20).jpg"),
        require("@/assets/img/full/Casa/terminados/img (21).jpg"),
        require("@/assets/img/full/Casa/terminados/img (22).jpg"),
        require("@/assets/img/full/Casa/terminados/img (23).jpg"),
        require("@/assets/img/full/Casa/terminados/img (24).jpg"),
        require("@/assets/img/full/Casa/terminados/img (3).jpg"),
        require("@/assets/img/full/Casa/terminados/img (4).jpg"),
        require("@/assets/img/full/Casa/terminados/img (5).jpg"),
        require("@/assets/img/full/Casa/terminados/img (6).jpg"),
        require("@/assets/img/full/Casa/terminados/img (7).jpg"),
        require("@/assets/img/full/Casa/terminados/img (8).jpg"),
        require("@/assets/img/full/Casa/terminados/img (9).jpg")
      ],
      breakpoints: breakpoints
    };
  },
  computed: {
    perPagePhotos() {
      if (breakpoints.is == "lg" || breakpoints.is == "xl") return 2;
      else return 1;
    }
  }
};
</script>

<style>
.icon-red {
  filter: drop-shadow(0 0 1.5px #ff000081);
  animation-name: icon-red;
  animation-duration: 4s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

@keyframes icon-red {
  from {
    filter: drop-shadow(0 0 0.1px #ff000081);
  }
  to {
    filter: drop-shadow(0 0 2px #ff000081);
  }
}

.icon-blue {
  filter: drop-shadow(0 0 1.5px #0033ff85);
  animation-name: icon-blue;
  animation-duration: 4s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

@keyframes icon-blue {
  from {
    filter: drop-shadow(0 0 0.1px #0033ff85);
  }
  to {
    filter: drop-shadow(0 0 2px #0033ff85);
  }
}
</style>
